import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO/SEO';

const Title404 = styled.h2`
  transform-origin: center center;
  transform: rotate(-3.142deg) translatey(-1rem);
`;

export default () => {
  return (
    <Layout>
      <SEO title="Page not found" pathname="/404/" />
      <div
        style={{
          padding: '0 1rem',
          transform: 'rotate(-3.142deg)'
        }}
      >
        <Title404>Oooh!</Title404>
        <p>
          If you were looking the for the 404 page, great job! Strange choice of
          page to visit, but great job anyway!
        </p>
        <p>
          If you weren't looking the 404 page.. you found it anyway. But don't
          worry, there's plenty of stuff to have a look at here. Why not have a
          look at some of my <Link to="/portfolio">projects</Link>, read through
          some <Link to="/#blog">blog</Link> posts, or find out a little more{' '}
          <Link to="/about">about me</Link>.
        </p>
      </div>
    </Layout>
  );
};
